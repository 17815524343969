import { useEffect, useState, useContext} from "react"
import { useSelector, useDispatch } from 'react-redux';
import { addData } from "../../utils/redux/dataSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { isEqual } from "lodash";

import Cards from "../visualizations/Cards";
import { UnpaidARChart } from '../visualizations/charts/PieCharts';
import { StaticWIPContext } from "../../pages/reports/wip/WIPreports";

import "../../style/static-ar-card.css";

export function StaticMetric(props) {
    const dispatch = useDispatch();
    const arData = useSelector(state => state.data.data[props.objKey]);
    const bearer = useSelector(state => state.user.bearer);

    useEffect(() => {
        // console.log("Data Endpoint: ",props.query);
        fetch(props.query, {
            method: 'GET',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                authorization: bearer
            },
        })
        .then(res => {
            return res.json()
        })
        .then(data => {
            // console.log(data)
            if (!isEqual(data[0], arData)) {
                dispatch(addData({objKey: props.objKey, data: data[0]}))
            }
        })
        .catch(err => {
            console.error(err)
        })
    }, [props, dispatch, arData])

    let label = "AR";
        let maintype = "UNPAID_INVOICE";
        switch (props.objKey.slice(0,3)) {
            case "arDeptStatic": 
                label = "AR";
                break;
            case "arRespStatic":
                label = "AR";
                break;
            // case "wipRespStatic":
            //     label = "WIP";
            //     maintype = "OUTSTANDING_WIP";
            //     break;
            // case "wipDeptStatic":
            //     label = "WIP";
            //     maintype = "OUTSTANDING_WIP";
            //     break;
            case "wip":
                label = "WIP";
                maintype = "OUTSTANDING_WIP";
                break;
            default:
                label = "AR";
        }
        
    try {
    if (arData || arData[maintype]) {
        return (
            <section className={`${props.stylesheet ? props.stylesheet : "static"}-metric-panel `}>
                {/* <h5>{props.header}</h5> */}
                <div id="data-area">
                    <div id="dollar-metrics">
                        <h6>Outstanding{` ${label}`}</h6>
                        {/* <h3 className="lrg-dollar-amount">{"$"+arData[maintype]}</h3> */}
                        <h3 className="lrg-dollar-amount">{"$"+arData[maintype].toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h3>
                    </div>
                    <div id="static-ar-chart">
                        <UnpaidARChart chartData={arData} centerLabel={props.header}/>
                    </div>
                    <div id="static-ar-cards">
                        {/* <Cards level={props.objKey} metric='UNPAID_INVOICE' /> */}
                        <Cards level={props.objKey} metric='PERCENT_CURR' color='#5C6FC5' cardValue={arData.PERCENT_CURR}/> 
                        <Cards level={props.objKey} metric='PERCENT_31_60' color='#95CB77' cardValue={arData.PERCENT_31_60}/>
                        <Cards level={props.objKey} metric='PERCENT_61_90' color='#F4CA5E' cardValue={arData.PERCENT_61_90}/>
                        <Cards level={props.objKey} metric='PERCENT_OVERDUE' color='#E66B68' cardValue={arData.PERCENT_OVERDUE}/>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <ClipLoader loading={arData} />
        )
    }
} catch (error) {
    console.error(error);
    return (
        <ClipLoader loading={arData} />
    )
}
}

export function StaticValues(dataTable, type) {
    
    const ComputPercentages = (values) => {
        let shape = {
            outstanding:values.outstanding, 
            PERCENT_31_60: (values["31-60 Days"] / numberOfEntries) * 100, 
            PERCENT_61_90: (values["61-90 Days"] / numberOfEntries) * 100, 
            PERCENT_OVERDUE: (values["Overdue WIP"] / numberOfEntries) * 100,  
            PERCENT_CURR: (values["Current"] / numberOfEntries) * 100, 
        }
        return shape
    }
    
    let outstanding;
    let numberOfEntries;
    if (dataTable !== null) {
        numberOfEntries = dataTable.length;
        outstanding = dataTable.reduce((acc, nxt)=>{
        acc.outstanding += nxt[type];
        acc[nxt.AGING_PERIOD] += 1
        return acc;
        }, {outstanding:0, "31-60 Days":0, "61-90 Days":0, "Overdue WIP":0, "Current":0})
        return ComputPercentages(outstanding);
    }
    
    return outstanding;
}

function labeler (type) {
    let label = "AR";
    let maintype = "UNPAID_INVOICE";
    switch (type.objKey.slice(0,3)) {
        case "arDeptStatic": 
            label = "AR";
            break;
        case "arRespStatic":
            label = "AR";
            break;
        case "wip":
            label = "WIP";
            break;
        default:
            label = "AR";
    }
}

export function StaticMetricView({data, header, label, stylesheet}) {
    // const { staticWIP, setStaticWIP, staticValue } = useContext(StaticWIPContext);
    
    const outstanding = data ? parseFloat(data.outstanding) : 0;
    const current = data ? data.PERCENT_CURR : 0;
    const overdue = data ? data.PERCENT_OVERDUE: 0;
    const thirty = data ? data.PERCENT_31_60: 0;
    const sixty = data ? data.PERCENT_61_90: 0;
    
    return (
        <section className={`${stylesheet ? stylesheet : "static"}-metric-panel `}>
            {/* <h5>{header}</h5> */}
            <div id="data-area">
                <div id="dollar-metrics">
                    <h6>Outstanding{` ${label}`}</h6>
                    {/* <h3 className="lrg-dollar-amount">{"$"+arData[maintype]}</h3> */}
                    <h3 className="lrg-dollar-amount">{"$"+outstanding.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h3>
                </div>
                <div id="static-ar-chart">
                    <UnpaidARChart chartData={data} centerLabel={header}/>
                </div>
                <div id="static-ar-cards">
                    {/* <Cards level={objKey} metric='UNPAID_INVOICE' /> */}
                    <Cards cardValue={current} metric='PERCENT_CURR' color='#5C6FC5'/> 
                    <Cards cardValue={thirty} metric='PERCENT_31_60' color='#95CB77'/>
                    <Cards cardValue={sixty} metric='PERCENT_61_90' color='#F4CA5E'/>
                    <Cards cardValue={overdue} metric='PERCENT_OVERDUE' color='#E66B68'/>
                </div>
            </div>
        </section>
    )
}