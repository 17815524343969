export var localTables = {
    CHANGE_LOG: `
    DATA_TABLE,
    HASH_VALUE`,

    CHECKINS: `
    INDEX,
    CHECKER,
    CHECKER_NAME,
    STAFF,
    STAFF_NAME,
    HOMEROOM_INDEX,
    HOMEROOM_NAME,
    DATE,
    WEEK
    `,

    CONTACTS: `
    INDEX,
    NAME,
    FIRST_NAME,
    LAST_NAME,
    ADDRESS,
    CITY,
    STATE,
    POSTAL_CODE,
    COUNTRY,
    PHONE,
    EMAIL,
    CELL_NUMBER,
    ORIGINATING_OFFICE,
    STATUS,
    CONTACT_CODE,
    IS_CLIENT,
    IS_FAMILY,
    UPDATED_ON,
    CREATED_ON
    `,

    COUNTRIES: `
    INDEX,
    VALUE,
    NAME
    `,

    ENGAGEMENT: `
    INDEX,
    CONTINDEX,
    CLIENTCODE,
    CLIENTNAME,
    CLIENTSHORTCODE,
    CLIENTORGANISATION,
    CLIENTOFFICE,
    CLIENTPARTNER,
    CLIENTMANAGER,
    CLIENTSTATUS,
    CLIENTOWNERSHIP,
    CLIENTRATING,
    CLIENTCREATED,
    CLIENTUPDATED,
    CLIENTUPDATEDBY,
    CLIENT_TYPE`,

    ENTERED_CLIENTS: `
    KEY,
    SUBMITTER,
    SUBMIT_TIME,
    CLIENT_NAME,
    CLIENT_BOI,
    STATUS,
    BOI_CLEARED
    `,

    ENTITY: `
    INDEX,
    NAME`,

    HOMEROOM: `
    INDEX,
    STAFFINDEX,
    EMPLOYEE,
    HOMEROOM,
    CHECKED`,

    INDUSTRIES: `
    INDEX,
    NAME`,

    JOBS: `
    INDEX,
    JOB_IDX,
    CONTINDEX,
    JOB_NAME,
    JOB_HOURS,
    STAFF_HOURS`,

    JOB_TEMPLATES: `
    INDEX,
    JOB_IDX,
    TMPL_IDX,
    TYPE,
    NAME,
    CODE,
    TOTAL`,

    MORALE: `
    INDEX,
    DATE,
    STAFF,
    STAFFNAME,
    LEVEL,
    DEPARTMENT,
    HOMEROOM,
    ADMIN,
    PERSONAL,
    SCHEDULE,
    TEAM,
    WEEK`,

    NEW_CLIENTS: `
    requestNumber,
    status,
    requestingEnity,
    requestingUser,
    clientName,
    clientRelationship,
    clientScope,
    officeSelection,
    relationshipValue,
    partnerSelection,
    managerSelection,
    entitySelection,
    industrySelection,
    clientAddress,
    clientCity,
    clientLocation,
    clientState,
    clientPostalCode,
    clientCountry,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhoneNumber,
    contactAddress,
    contactCity,
    contactState,
    contactPostalCode,
    contactSameAsClient,
    FYE,
    estimatedFeesClientLevel,
    estHrsClientLevel,
    billingEmail,
    billingAddress,
    billingCity,
    billingState,
    billingPostalCode,
    billingSameAsClient,
    billingSameAsContact,
    invoiceMethod,
    ServiceSelection,
    services`,

    OFFICES: `
    INDEX,
    NAME,
    PHONE,
    FAX,
    ADDRESS`,

    // OUTSTANDING_WIP: `
    // id++,
    // OUTSTANDING_WIP,
    // CLIENT_PARTNER,
    // CLIENT_MANAGER,
    // CLIENT,
    // CODE,
    // OFFICE,
    // CLIENT_PARTNER_IDX,
    // CLIENT_MANAGER_IDX,
    // CLIENT_PARTNER_DEPT,
    // CLIENT_MANAGER_DEPT,
    // PARTNER_HOMEROOM,
    // MANAGER_HOMEROOM,
    // AGING_PERIOD`,

    REQUESTS: `
    INDEX,
    REQUEST_INDEX,
    DATE,
    SUBMIT_USER,
    SUBMIT_EMPLOYEE,
    SUBMIT_HOMEROOM,
    SUBMIT_LEVEL,
    SUBMIT_DEPARTMENT,
    SUBMIT_EMAIL,
    REQUEST_USER,
    REQUEST_EMPLOYEE,
    REQUEST_HOMEROOM,
    REQUEST_LEVEL,
    REQUEST_DEPARTMENT,
    JOB,
    JOB_NAME,
    JOB_HOURS,
    JOB_STATUS,
    STAFF_HOURS,
    CLIENT,
    CLIENT_NAME,
    REQUEST_STATUS,
    WEEK`,

    REVIEWS: `
    INDEX,
    REVIEW_USER,
    REVIEW_EMPLOYEE,
    REVIEW_HOMEROOM,
    REVIEW_LEVEL,
    REVIEW_DEPARTMENT,
    SUBMIT_USER,
    SUBMIT_EMPLOYEE,
    SUBMIT_HOMEROOM,
    CLIENT_NAME,
    JOB_NAME,
    RATING,
    SEE_MORE,
    SEE_LESS,
    DATE,
    DIRECTION,
    WEEK`,

    REVIEWS_GOALS: `
    INDEX,
    STAFFINDEX,
    EMPLOYEE,
    STAFFLEVEL,
    HOMEROOM,
    QUAD_GOAL,
    CURRENT_QUAD_ROLOS,
    PREVIOUS_GOAL,
    PREV_QUAD_ROLOS,
    YEARLY_GOAL,
    MET_CURR_GOAL,
    MET_PREV_GOAL`,

    SERVICES: `
    INDEX,
    SERVCODE,
    SERVNAME,
    SERVORGANISATION,
    SERVPERSON`,

    STAFF: `
    STAFFINDEX,
    EMPLOYEE,
    EMAIL,
    IS_MANAGER,
    CLIENT_RESPONSIBLE,
    OFFICE,
    DEPARTMENT,
    BVSA,
    BILLABLE,
    LEVEL,
    HOMEROOM`,

    TIME_CALENDAR: `
    INDEX,
    DATE,
    STAFFINDEX,
    COMPLIANCE,
    MIN_FALSE,
    MAX_TRUE,
    CURRENT_STREAK`, // THIS IS THE STAFF_CALENDAR_VIEW

    TIME_COMPLIANCE: `
    INDEX,
    STAFFINDEX,
    EMPLOYEE,
    STAFF_TYPE,
    LEVEL,
    TOTAL,
    TOTAL_COMPLIANT,
    COMPLIANT_PERCENT,
    COMPLIANT_RANGE`, // THIS IS THE TIME_COMPLIANCE_VIEW

    TIME_STREAK: `
    INDEX,
    STAFFINDEX,
    MIN_FALSE,
    MAX_TRUE,
    CURRENT_STREAK`, // THIS IS THE FIRM_STREAK_VIEW

    // STATIC: `
    // FOCUS,
    // OUTSTANDING,
    // PERCENT_CURR,
    // PERCENT_31_60,
    // PERCENT_61_90,
    // PERCENT_OVERDUE`,

    UNPAID_AR: `
    id++,
    UNPAID_INVOICE,
    CLIENT_PARTNER,
    CLIENT_MANAGER,
    CLIENT,
    OFFICE,
    CLIENT_PARTNER_IDX,
    CLIENT_MANAGER_IDX,
    CLIENT_PARTNER_DEPT,
    CLIENT_MANAGER_DEPT,
    AGING_PERIOD`,

    WIP: `
    INDEX,
    CONTINDEX,
    CLIENT,
    CLIENT_PARTNER,
    CLIENT_MANAGER,
    JOBIDX,
    JOB_NAME,
    JOB_PARTNER,
    JOB_MANAGER,
    SERVICETITLE,
    WIPHOURS,
    WIPAMOUNT,
    WIPOUTSTANDING,
    OUTSTANDING_CURRENT,
    OUTSTANDING_31_60,
    OUTSTANDING_61_90,
    OUTSTANDING_OVERDUE`
};
