import Dexie from "dexie";
import { localTables } from "./DexieTables";

// Tables to be generated with highest level of permission.  For instance if the highest level is
// client_manger then the MagnifyDB will only be populated with that level.

export const db = new Dexie("MagnifyDB");
db.version(4.2).stores(localTables);
db.open()
    .catch(Dexie.VersionError, (error) => {
        console.error("Error with upgrade of Dexie version. Rebuilding MagnifyDB");
        db.delete();
        window.location.reload(true);
    })
    .catch(Dexie.UpgradeError, (error) => {
        console.error("Error with upgrade of Dexie version. Rebuilding MagnifyDB");
        db.delete();
        window.location.reload(true);
    })
    .catch((err) => {
        console.error(err.stack || err)
    });

db.on("versionchange", (event) => {
    console.warn("Version Change Happened");
    db.delete();
    window.location.reload(true);
});

export function DataRefreshTimer(cb, data, startTime) {
    // Still working on this
    const interval = 1 * 1000;
    let targetTime = Date.now() + interval;
    if (Date.now() <= targetTime) {
        targetTime += interval;
        cb(targetTime);
        requestAnimationFrame(DataRefreshTimer(targetTime, cb));
    } else {
    }
    window.requestAnimationFrame(DataRefreshTimer(targetTime, cb));
}

export async function UpdateLessChangelog(dataRequests, localDB, headers) {
    try {
        fetch(dataRequests.endpoint, {
            method: "GET",
            mode: "cors",
            headers: headers,
        })
            .then((resp) => resp.json())
            .then(async (data) => {
                // console.log(dataRequests.endpoint);
                const dataLoad = { FOCUS: dataRequests.table, ...data[0] };
                localDB["STATIC"].put(dataLoad);
            });
    } catch (error) { }
}

export const GetRemoteVersions = async (remoteObject) => {
    const version_data = await fetch(remoteObject.changeTable, {
        method: "GET",
        mode: "cors",
        headers: remoteObject.tableHeaders,
    });
    return version_data.json();
};

export function DataRefresher(
    remoteDataRequests,
    localDB,
    headers,
    remoteChangeTable = null
) {
    const get_stored_version = async (checkTable) => {
        let resp = {};
        resp = await localDB.CHANGE_LOG.where("DATA_TABLE")
            .equals(checkTable.tableName)
            .first();
        // console.log(`The Local Hash Version For ${checkTable.tableName} is ${resp}`)
        try {
            checkTable["localHash"] = resp.HASH_VALUE;
        } catch {
            checkTable["localHash"] = null;
        }
        // checkTable['localHash'] = null
        return checkTable;
    };

    const update = new Promise((cb) => {
        const tableName = remoteDataRequests.table;
        let remoteHash = remoteDataRequests.remoteversion;
        // console.log(`Remote ${tableName} hash version: ${remoteHash}`);

        // console.log(`Fetching Stored Hash Version For ${tableName}`);
        get_stored_version({ tableName: tableName })
            .then((data) => {
                let localHash = null;
                try {
                    localHash = data.localHash;
                    // console.log(`${localHash} - ${remoteHash}`)
                } catch {
                    console.log("Stored Hash Value Not Available");
                    remoteHash = null;
                }
                if (data.localHash === true) {
                    console.log(`Refreshing ${data.tableName} table`);
                    return data;
                } else if (remoteHash === localHash) {
                    // console.log("Version Match");
                    console.log(
                        `${data.tableName} data current relative to intermediate database.`
                    );
                    // return Promise.reject(()=>{console.log("Versions Match")})
                    return false;
                } else {
                    console.log("Versions Do Not Match!");
                    // console.log(data.tableName, "Stored Version: ", localHash);
                    // console.log(data.tableName, "Remote Version: ", remoteHash);
                    console.log(
                        `${data.tableName} outdated syncing to intermediate database`
                    );
                    // return load_remote_data(remoteDataRequests.endpoint, data);
                    return data;
                }
            })
            .then(async (contextData) => {
                if (contextData === false) {
                    return "Version Up To Date";
                } else {
                    //   await localDB[contextData.tableName].clear();
                    const response = await fetch(remoteDataRequests.endpoint, {
                        method: "GET",
                        mode: "cors",
                        headers: headers,
                    });
                    response
                        .json()
                        .then((respData) => {
                            // console.log(contextData.tableName)
                            // console.log(respData)
                            let action = localDB[contextData.tableName].bulkPut(respData);
                            action.then((dexieResp) => {
                                let updateHash = localDB.CHANGE_LOG.put({
                                    DATA_TABLE: contextData.tableName,
                                    HASH_VALUE: remoteHash,
                                });

                                const sourceKeys = contextData.tableName === 'ENTERED_CLIENTS' ? respData.map(row => row.KEY) : respData.map(row => row.INDEX);
                                localDB[contextData.tableName]
                                    .toArray()
                                    .then(localData => {
                                        const localKeys = contextData.tableName === 'ENTERED_CLIENTS' ? localData.map(row => row.KEY) : localData.map(row => row.INDEX);
                                        const keysToDelete = localKeys.filter(key => !sourceKeys.includes(key));

                                        // console.log(`found ${keysToDelete.length} keys to delete from ${contextData.tableName}`)

                                        if (keysToDelete.length > 0) {
                                            localDB[contextData.tableName].bulkDelete(keysToDelete)
                                                .then(() => {
                                                    console.log(`Removed ${keysToDelete.length} old values from ${contextData.tableName}`);
                                                })
                                                .catch(err => {
                                                    console.error(`Error removing old values from ${contextData.tableName}`, err);
                                                });
                                        }
                                    });

                                cb();
                                return updateHash;
                                //     .then((status)=>{
                                //         console.log(`Synced: ${status}`);
                                //         cb();
                                // })
                            })
                                .catch((err) => {
                                    console.log(contextData.tableName);
                                    console.error(err);
                                })
                            return respData
                        })
                        .catch(err => {
                            console.error(`Error refreshing ${contextData.tableName}`, err);
                        });
                    // .then(data => {
                    // if (contextData.tableName === 'ENTERED_CLIENTS') {
                    //     const source_keys = data.map(row => row.KEY)
                    //     const local_key_table = localDB['ENTERED_CLIENTS'].toArray()
                    //     local_key_table.then(table => {
                    //         if (source_keys.length < local_key_table.length) {
                    //             const local_keys = table.map(row => row.KEY)
                    //             const keys_to_delete = local_keys.filter(value => !source_keys.includes(value))
                    //             localDB['ENTERED_CLIENTS'].bulkDelete(keys_to_delete)
                    //                 .then(() => {
                    //                     console.log(`Old values removed from ${contextData.tableName}`)
                    //                 })
                    //         }
                    //     })
                    //         .catch(err => {
                    //             console.error('Error removing old values from ENTERED_CLIENTS')
                    //             console.error(err)
                    //         })
                    // } else {
                    //     const source_keys = data.map(row => row.KEY)
                    //     const local_key_table = localDB[contextData.tableName].toArray()
                    //     local_key_table.then(table => {
                    //         if (source_keys.length < local_key_table.length) {
                    //             const local_keys = table.map(row => row.KEY)
                    //             const keys_to_delete = local_keys.filter(value => !source_keys.includes(value))
                    //             localDB[contextData.tableName].bulkDelete(keys_to_delete)
                    //                 .then(() => {
                    //                     console.log(`Old values removed from ${contextData.tableName}`)
                    //                 })
                    //         }
                    //     })
                    //         .catch(err => {
                    //             console.error(`Error removing old values from ${contextData.tableName}`)
                    //             console.error(err)
                    //         })
                    // }
                    // })
                    // .catch((err) => {
                    //     console.log(contextData.tableName);
                    //     console.error(err);
                    // });
                }
            });
    });
    return update;
}

export const RefreshData = async (remoteDataRequests, localDB, headers) => {

    const get_stored_version = async checkTable => {
        let resp = {}

        resp = await localDB.CHANGE_LOG
            .where('DATA_TABLE')
            .equals(checkTable.tableName)
            .first()

        try {
            checkTable['localHash'] = resp.HASH_VALUE
        } catch {
            checkTable['localHash'] = null
        }

        return checkTable
    }

    const update = new Promise(cb => {

        const tableName = remoteDataRequests.table
        let remoteHash = remoteDataRequests.remoteversion

        get_stored_version({ tableName: tableName })
            .then(data => {
                let localHash = null
                try {
                    localHash = data.localHash
                } catch {
                    remoteHash = null
                }

                if (data.localHash === true) {
                    console.log(`Refreshing ${data.tableName} table`)
                    return data
                } else if (remoteHash === localHash) {
                    console.log(
                        `${data.tableName} data current relative to intermediate database.`
                    );
                    return false
                } else {
                    console.log(`${data.tableName} is outdated, updating...`)
                    return data
                }
            })
            .then(async contextData => {
                if (contextData === false) {
                    return
                } else {
                    // await localDB[contextData.tableName].clear() // this this line is causing the blink issue
                    await fetch(remoteDataRequests.endpoint, {
                        method: 'GET',
                        mode: 'cors',
                        headers: headers
                    })
                        .then(data => {
                            return data.json()
                        })
                        .then(async data => {
                            console.log(`Received ${data.length} entries from ${contextData.tableName} API`);
                            try {
                                // Use bulkPut, but with error handling
                                await localDB[contextData.tableName].bulkPut(data)
                                    .then(() => {
                                        console.log(`Successfully put ${data.length} entries into ${contextData.tableName}`);
                                    })
                                    .catch(Dexie.BulkError, error => {
                                        console.warn(`Some ${contextData.tableName} operations failed: ${error.failures.length} failures`);
                                    });

                                const updateHash = await localDB.CHANGE_LOG.put({
                                    DATA_TABLE: contextData.tableName,
                                    HASH_VALUE: remoteHash
                                });

                                const sourceKeys = contextData.tableName === 'ENTERED_CLIENTS' ? data.map(row => row.KEY) : data.map(row => row.INDEX);
                                localDB[contextData.tableName]
                                    .toArray()
                                    .then(localData => {
                                        const localKeys = contextData.tableName === 'ENTERED_CLIENTS' ? localData.map(row => row.KEY) : localData.map(row => row.INDEX);
                                        const keysToDelete = localKeys.filter(key => !sourceKeys.includes(key));

                                        console.log(`found ${keysToDelete.length} keys to delete from ${contextData.tableName}`)

                                        if (keysToDelete.length > 0) {
                                            localDB[contextData.tableName].bulkDelete(keysToDelete)
                                                .then(() => {
                                                    console.log(`Removed ${keysToDelete.length} old values from ${contextData.tableName}`);
                                                })
                                                .catch(err => {
                                                    console.error(`Error removing old values from ${contextData.tableName}`, err);
                                                });
                                        }
                                    });
                                cb();
                                return updateHash;
                            } catch (err) {
                                console.error(`Error refreshing ${contextData.tableName}:`, err);
                                throw err;
                            }
                        })
                        .then(() => {
                            console.log(`Refreshed ${tableName} successfully`);
                        })
                        .catch(err => {
                            console.error('Refresh data outer function error', err);
                        });
                }
            })
    })

    return update
}
